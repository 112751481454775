import 'src/hooks/useFilters/index.scss';
import React, { useState } from 'react';
import Filter from 'src/components/atoms/filter';
import Icon from 'src/components/atoms/icon';

import { DATA_LAYER_NAME } from '../useApi';

import { MultiSelect } from 'react-multi-select-component';

export default ({ initialOptions = [], options = [] }: { initialOptions?: Array<string>, options?: Array<string> }) => {
  const [ activeFilters, setActiveFilters ] = useState<Array<string>>(initialOptions);

  const isFilterActive = (option: string) => !!activeFilters.find(element => element == option);

  const withFilter = (option: string) => activeFilters.filter(element => element !== option);
  const withoutFilter = (option: string) => [ ...activeFilters, option ];

  const handleFilter = (option: string) => {
    const filters = isFilterActive(option) ? withFilter(option) : withoutFilter(option);
    const filterAction: string = isFilterActive(option) ? 'remove' : 'apply';
    FiltersSEO(filters, filterAction);
    setActiveFilters(filters);
  };

  function FiltersSEO(filters: string[], filterAction: string) {
    let filtersString = '';
    filters.forEach((category: string) => {
      filtersString = filtersString + category + '|';
    });
    pushFilters(filtersString.slice(0, -1), filterAction);
  }

  const push = (event: any) => (window as any)?.[DATA_LAYER_NAME]?.push?.(event);
  const getBrand = () => location.hostname?.split('.')?.reverse()?.[1] ?? '';

  const pushFilters = (filters: string, filterAction: string) => push({
    event: 'filter',
    filterInfo: {
      filterAction: filterAction,
      filterLocation: 'product chart',
      filterTerm: filters,
      filterType: 'ANCILLARY SERVICE'
    },
    global: {
      brand: getBrand(),
      eventAction: 'filter'
    }
  });

  const dropDownOptions: any = [];

  options.forEach(option => {
    dropDownOptions.push({ label: option, value: option });
  });

  const [ selected, setSelectedFilter ] = useState([]);

  function handleSelect(filter: any) {
    setSelectedFilter(filter);

    const filters: any = [];
    filter.forEach((option: any) => {
      filters.push(option.value);
    });

    const mobileFilters = JSON.parse(JSON.stringify(sessionStorage.getItem('mobile-filters')));
    const previousFilters = mobileFilters ? mobileFilters.split(',') : [];
    const filterAction: string = previousFilters.every((f: any) => filters.includes(f)) ? 'apply' : 'remove';
    sessionStorage.setItem('mobile-filters', filters);
    FiltersSEO(filters, filterAction);
    setActiveFilters(filters);
  }

  const Filters = ({ label }: { label: string }) => (
    <>
      <div className='filters'>
        <div className='filters--headers'>
          <Icon type='filter' />
          <div>{label}</div>
        </div>
        <div className='filters--content'>
          {options.map((option: string) => (
            <Filter
              key={option}
              label={option}
              active={isFilterActive(option)}
              onClick={() => handleFilter(option)}
            />
          ))}
        </div>
      </div>

      <MultiSelect
        className='mobile-filter'
        options={dropDownOptions}
        value={selected}
        onChange={handleSelect}
        labelledBy='Select'
        hasSelectAll={false}
        disableSearch={true}
      />
    </>

  );

  return {
    Filters,
    activeFilters: activeFilters.length > 0 ? [ ...activeFilters ].reverse() : options
  };
};