import React from 'react';

interface Props {
  className?: string;
  fill?: string;
  height?: string;
  width?: string;
}

export default ({ className, fill = '#ef3e42', height = '14', width = '14' }: Props) => (
  /* eslint-disable max-len */
  <svg className={className} width={width} height={height} style={{ flexShrink: '0' }} viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M7 5.83337V7.00004M7 9.33337V9.33921' stroke={fill} strokeWidth='1.83333' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M2.91686 11.6669H11.0835C11.2739 11.6656 11.461 11.6177 11.6286 11.5274C11.7962 11.4372 11.9391 11.3073 12.045 11.1491C12.1508 10.9909 12.2164 10.8092 12.2359 10.6198C12.2554 10.4305 12.2282 10.2392 12.1569 10.0628L8.01519 2.91692C7.9143 2.73457 7.7664 2.58257 7.58688 2.47673C7.40736 2.37089 7.20276 2.31506 6.99436 2.31506C6.78596 2.31506 6.58136 2.37089 6.40184 2.47673C6.22231 2.58257 6.07442 2.73457 5.97353 2.91692L1.83186 10.0628C1.76182 10.2352 1.73405 10.4218 1.75086 10.6072C1.76767 10.7925 1.82858 10.9712 1.92849 11.1282C2.0284 11.2852 2.16442 11.416 2.3252 11.5097C2.48598 11.6034 2.66684 11.6573 2.85269 11.6669' stroke={fill} strokeWidth='1.83333' strokeLinecap='round' strokeLinejoin='round'/>
  </svg>
);
