import React from 'react';

interface Props {
  fill?: string;
  height?: string;
  width?: string;
}

export default ({ fill = 'black', height = '7', width = '15' }: Props) => (
  /* eslint-disable max-len */
  <svg width={width} height={height} viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M1.5 1.25L7 6.75L12.5 1.25L1.5 1.25Z' stroke={fill} strokeWidth='1.83333' strokeLinecap='round' strokeLinejoin='round'/>
  </svg>

);
