import React from 'react';

interface Props {
  fill?: string;
  height?: string;
  width?: string;
}

export default ({ fill = 'white' }: Props) => (
  /* eslint-disable max-len */
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='16' cy='16' r='15.75' fill='white' stroke='#D7D7D7' strokeWidth='0.5'/>
    <g clipPath='url(#clip0_1096_80810)'>
      <path d='M23.1483 19.5956C23.9057 18.0924 24.1696 16.3886 23.9025 14.7267C23.6354 13.0648 22.8509 11.5295 21.6607 10.3392C20.4705 9.14902 18.9352 8.36454 17.2733 8.09746C15.6114 7.83037 13.9075 8.09428 12.4043 8.85164M10.3439 10.3423C9.59093 11.0828 8.99205 11.965 8.58177 12.9381C8.1715 13.9112 7.95795 14.9559 7.95344 16.012C7.94894 17.068 8.15356 18.1145 8.55552 19.0911C8.95748 20.0677 9.54881 20.955 10.2954 21.7019C11.042 22.4488 11.9291 23.0404 12.9055 23.4428C13.8819 23.8451 14.9283 24.0502 15.9844 24.0461C17.0404 24.042 18.0852 23.8288 19.0585 23.4189C20.0317 23.0091 20.9142 22.4105 21.655 21.6579' stroke='#231F20' strokeWidth='0.916667' strokeLinecap='round' strokeLinejoin='round'/>
      <path d='M8 8L24 24' stroke='#231F20' strokeWidth='0.916667' strokeLinecap='round' strokeLinejoin='round'/>
    </g>
    <defs>
      <clipPath id='clip0_1096_80810'>
        <rect width='21.3333' height='21.3333' fill={fill} transform='translate(5.33325 5.33337)'/>
      </clipPath>
    </defs>
  </svg>
);