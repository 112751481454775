import 'src/components/layout/index.scss';
import Cart, { MobileCart } from 'src/components/molecules/cart';
import { ContextProvider, EnrollmentContext } from './context-provider';
import React, { ReactNode, createRef, useContext, useEffect, useState } from 'react';
import { useApi, useApiState, useCart, useConfig, useDeviceDetection, useUrl } from 'src/hooks';
import Footer from 'src/components/layout/footer';
import Header from 'src/components/layout/header';
import Limitations from 'src/components/molecules/limitations';
import Main from 'src/components/layout/main';
import Modal from 'src/components/atoms/modal';
import Overlay from 'src/components/atoms/overlay';
import { PAGES } from 'src/constants';
import Spinner from 'src/components/atoms/spinner';
import { navigate } from 'gatsby';

const { CHECK_OUT, CONFIRMATION, SHOPPING } = PAGES;

interface Props {
  children: ReactNode;
  location: {
    pathname: string,
    search?: string
  };
}

const getPageConfig = (page = '') => {
  const config = {
    [CHECK_OUT]: {
      hasCart: { isLocked: true, withButton: false },
      progressComplete: 67
    },
    [CONFIRMATION]: {
      progressComplete: 100
    },
    [SHOPPING]: {
      hasCart: { isLocked: false, withButton: true },
      hasLimitations: true,
      progressComplete: 33
    }
  };

  return config[page] ?? {};
};

if (typeof window !== 'undefined') {
  sessionStorage.removeItem('queryParams');
  sessionStorage.setItem('queryParams', location?.search || '');
}

const MobileLayout = ({ children, location }: Props) => {
  const { hasCart, hasLimitations, progressComplete } = getPageConfig(location?.pathname);
  const { limitations } = useConfig();
  const [ cartHeight, setCartHeight ] = useState(0);
  const ref = createRef<HTMLDivElement>();

  useEffect(() => setCartHeight(ref?.current?.clientHeight ?? 0), [ ref ]);

  return (
    <>
      <Header {...{ progressComplete }} />
      <Main className='mobile-layout--main'>
        <div className='mobile-layout--main--content'>{children}</div>
        {hasLimitations && <Limitations limitations={limitations} />}
      </Main>
      <Footer />
      {hasCart && <>
        <MobileCart {...hasCart} ref={ref} />
        <div className='mobile-layout--fixed-space' style={{ height: cartHeight }}></div>
      </>}
    </>
  );
};

const DefaultLayout = ({ children, location }: Props) => {
  const { hasCart, hasLimitations, progressComplete } = getPageConfig(location?.pathname);
  const { limitations } = useConfig();

  return (
    <>
      <Header {...{ progressComplete }} />
      <Main>
        <div className='layout--main'>
          <div className='layout--main--content'>{children}</div>
          {hasCart && <div className='layout--main--cart'><Cart {...hasCart} /></div>}
        </div>
        {hasLimitations && <Limitations limitations={limitations} />}
      </Main>
      <Footer />
    </>
  );
};

const getCookie = (cName: string) => {
  const name = cName + '=';
  const cookieDecoded = decodeURIComponent(document.cookie);
  const cookies = cookieDecoded.split('; ');
  let cookieValue;
  cookies.forEach(cookie => {
    if (cookie.includes((name))) {
      // eslint-disable-next-line prefer-destructuring
      cookieValue = cookie.split('=')[1];
    }
  });

  return cookieValue;
};

export default ({ children, location }: Props) => {
  const [ loadingState, setLoadingState ] = useState('');
  const [ maintenanceState, setMaintenanceState ] = useState('');
  const { alliedCSWBPlans, expressValidationCheckout, getPlans, maintenance } = useApi();
  const { initialCart } = useApiState() as any;
  const { getQueryParams } = useUrl();
  const { updateCart } = useCart() as any;

  const SUCCESS = 'SUCCESS';
  const FAILURE = 'FAILURE';
  let queryParams: any;
  if (typeof window !== 'undefined') {
    queryParams = new URLSearchParams(window.location.search);
  }

  const { setSharedState } = useContext(EnrollmentContext);
  const checkoutId = queryParams?.get('checkout_id');
  const serviceagreementid = queryParams?.get('serviceagreementid');
  const customerid = queryParams?.get('customerid');

  useEffect(() => {
    maintenance(() => setMaintenanceState(SUCCESS), () => setMaintenanceState(FAILURE));
  }, []);

  useEffect(() => {
    if (maintenanceState === FAILURE) {
      sessionStorage.removeItem('expressCheckoutPlans');
      sessionStorage.removeItem('expressCheckoutPlansDetails');
      sessionStorage.removeItem('isExpressCheckout');
      sessionStorage.removeItem('isAllied');
      const { category, plan, zipcode, zip, sps, campaign } = getQueryParams();
      let filters = category?.toString().replaceAll('-and-', ' & ');
      filters = filters?.toString().replaceAll('-', ' ');
      if (sps) {
        sessionStorage.setItem('sps', formatSpsValue(sps)?.toString());
      }
      if (campaign) {
        sessionStorage.setItem('campaign', formatSpsValue(campaign)?.toString());
      }
      // eslint-disable-next-line
      checkoutId ? startExpressCheckout(checkoutId) : serviceagreementid && customerid ? startAllied(serviceagreementid, customerid) : fetchPlans(filters, category, plan, zipcode, zip, sps, campaign, SUCCESS, FAILURE);

    }
  }, [ maintenanceState ]);

  useEffect(() => {
    if (loadingState === SUCCESS) {
      const route = initialCart?.length ? CHECK_OUT : SHOPPING;

      initialCart?.forEach((cartItem: any) => updateCart({ amount: 1, plan: cartItem }));
      const queryParams = sessionStorage.getItem('queryParams');
      if (queryParams) {
        navigate(route + queryParams, { replace: true });
      } else {
        navigate(route, { replace: true });
      }
    }
  }, [ loadingState ]);

  const { isDesktop } = useDeviceDetection();

  const Layout = isDesktop ? DefaultLayout : MobileLayout;

  const formatSpsValue = (value: string | string[]) => Array.isArray(value) ? value.map(v => v?.toLowerCase()) : value?.toLowerCase();

  const startExpressCheckout = (checkoutId: string) => {
    sessionStorage.setItem('checkoutId', checkoutId);
    sessionStorage.setItem('isExpressCheckout', 'true');
    setSharedState({
      checkoutId,
      isExpressCheckout: true
    });

    const onComplete = () => {
      navigate(CHECK_OUT);
    };
    // eslint-disable-next-line
    expressValidationCheckout({}, onComplete, () => { });

  };

  const startAllied = (serviceagreementid: string, customerid: string) => {
    sessionStorage.setItem('customerid', customerid);
    sessionStorage.setItem('serviceagreementid', serviceagreementid);
    sessionStorage.setItem('isAllied', 'true');
    setSharedState({
      customerid,
      isExpressCheckout: true,
      serviceagreementid
    });

    const onComplete = () => {
      navigate(SHOPPING);
    };
    // eslint-disable-next-line
    alliedCSWBPlans({ serviceagreementid, customerid }, onComplete, () => { });

  };

  const fetchPlans = (filters: string | string[], category: string | string[], plan: string | string[], zipcode: string | string[],
    zip: string | string[], sps: string | string[], campaign: string | string[], SUCCESS: string, FAILURE: string) => {
    const request = {
      categories: filters || '',
      initialPlans: plan || '',
      promoCode: campaign || '',
      sps: formatSpsValue(sps) || '',
      zipcode: zipcode || zip || getCookie('zipcode') || localStorage.getItem('zipcode') || ''
    };
    sessionStorage.setItem('promoCode', request.promoCode.toString());
    getPlans(request, () => setLoadingState(SUCCESS), () => setLoadingState(FAILURE));
  };

  return (
    <>
      <ContextProvider>
        <Layout location={location}>{children}</Layout>
        <Modal />
        <Spinner />
        <Overlay />
      </ContextProvider>
    </>
  );
};
