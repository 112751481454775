import 'src/components/modals/search/index.scss';
import Form from 'src/components/atoms/form';
import FormValidation from 'src/form-validations/search-modal';
import Input from 'src/components/atoms/input';
import React from 'react';

interface Props {
  onSubmit: any
}

export default ({ onSubmit }: Props) => (
  <div className='modal-search'>
    <div className='modal-search--header'>Confirm your zip code</div>
    <div className='modal-search--input'>
      <Form
        buttonLabel='View plans'
        id='SEARCH_MODAL_FORM'
        validation={FormValidation}
        onSubmit={onSubmit}
      >
        <Input
          name='zipcode'
          icon='pin'
          label='Zip code'
          placeholder='Enter zip'
          type='text'
          inputMode='numeric'
          maxLength={5}
        />
      </Form>
    </div>
  </div>
);