import 'src/components/atoms/button/index.scss';
import React from 'react';
import { useTheme } from 'src/hooks';

interface Props {
  className?: string;
  disabled?: boolean;
  label: string;
  onClick?: () => void;
  theme?: 'primary' | 'gray';
  type?: 'submit';
}

export default ({ className = '', theme, label, ...props }: Props) => {
  const { backgroundColor, color } = useTheme(theme);
  const borderColor = theme ? backgroundColor : 'black';

  return (
    <button
      type='button'
      className={`button ${className}`}
      style={{ backgroundColor, borderColor, color }}
      {...props}
    >
      {label}
    </button>
  );
};
