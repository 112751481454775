import 'src/components/atoms/select-quantity/index.scss';
import React, { useState } from 'react';
import Icon from 'src/components/atoms/icon';

interface Props {
  onChange?: (amount: number) => void;
  max?: number;
  value?: number;
}

export default ({ onChange, max = 10, value = 1 }:Props) => {
  const [ isOpen, setIsOpen ] = useState(false);

  const isDisabled = max === 1;
  const hasDropDown = !isDisabled && isOpen;

  return (
    <div className={`select-quantity ${isDisabled ? 'disabled' : ''}`}>

      <label className='select-quantity--label' role='label' onClick={() => setIsOpen(!isOpen)}>
        <span>{value}</span>
        <Icon type='arrowDown'/>
      </label>

      <ul style={{ display: hasDropDown ? 'block' : 'none' }}>
        {Array(max).fill(0).map((_, i) => (
          <li key={i} onClick={() => {
            onChange?.(i + 1);
            setIsOpen(!isOpen);}
          }>
            {i + 1}
          </li>
        ))}
      </ul>

    </div>
  );
};