import { useConfigCat, useSpinner } from 'src/hooks';

import { useEffect, useState } from 'react';

const UNRECOVERABLE_ERROR = 'Something has gone wrong. Please pay with Bank account or refresh the page to try again';
export const DATA_LAYER_NAME = 'adobeDataLayer';

const push = (event: any) => (window as any)?.[DATA_LAYER_NAME]?.push?.(event);
const getBrand = () => location.hostname?.split('.')?.reverse()?.[1] ?? '';

const purchaseComplete = (errorMessage: string) => {
  const serviceInfo = sessionStorage.getItem('serviceInfo');
  let serviceInformation = null;
  if (serviceInfo) {
    serviceInformation = JSON.parse(serviceInfo);
  }
  const billingInfo = sessionStorage.getItem('billingInfo');
  let billingInformation = null;
  if (billingInfo) {
    billingInformation = JSON.parse(billingInfo);
  }
  const checkout = {
    stepDescription: 'Payment Information',
    stepName: 'Step 3',
    stepStatus: 'Error'
  };
  pushCheckOutSteps(billingInformation, checkout, errorMessage, null, serviceInformation);
};

const pushCheckOutSteps = (billingInfo?: any, checkout?: any, errorMessage?: any, modal?: any, serviceInfo?: any) => push({

  billingInfo: billingInfo,
  checkout: checkout,
  event: 'checkoutSteps',
  global: {
    brand: getBrand(),
    eventAction: 'checkoutSteps'
  },
  messages: {
    error: errorMessage
  },
  modal: modal,
  serviceInfo: serviceInfo
});

export default ({ onSubmit }: any) => {
  const [ paymentForm, setPaymentForm ] = useState<any>({});
  const [ error, setError ] = useState('');

  const { hideSpinner, showSpinner } = useSpinner();
  const { commerceHubConfigs } = useConfigCat();
  const { form, verification } = commerceHubConfigs as any;
  const { merchantId, terminalId, apiKey } = form as any;

  useEffect(() => {
    paymentForm?.on?.('focus', () => {
      error === UNRECOVERABLE_ERROR || setError('');
    });
  }, [ error ]);

  const makeVerificationApiCall = ( secureCreds: any, address: any, merchantId: string, terms: any, onSubmit: any) => {
    const { url } = verification;
    const serviceInfo = sessionStorage.getItem('serviceInfo');
    let serviceInformation = null;

    if (serviceInfo) {
      serviceInformation = JSON.parse(serviceInfo);
    }

    const data = {
      Clientrequestid: secureCreds.clientRequestId,
      accesstoken: secureCreds.accessToken,
      billingAddress: {
        address: {
          city: address.city,
          country: 'USA',
          houseNumberOrName: '',
          postalCode: address.zipcode,
          stateOrProvince: address.state,
          street: address.address1
        },
        firstName: serviceInformation?.firstName ?? '',
        lastName: serviceInformation?.lastName ?? ''
      },
      merchantDetails: {
        merchantId: merchantId,
        terminalId: terminalId
      }
    };

    fetch(url, {
      body: JSON.stringify(data), // Convert data to JSON string
      headers: {
        'Content-Type': 'application/json' // Set content type to JSON
      },
      method: 'POST' // HTTP method
    })
      .then((response) => {
        if (!response.ok) {
          // Handle HTTP errors
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return response.json(); // Parse the JSON response
      }).then((verificationResponse) => {
        hideSpinner();
        const onFailure = () => {
          setError(UNRECOVERABLE_ERROR);
          purchaseComplete(UNRECOVERABLE_ERROR);
        };

        const creditCard = verificationResponse.credit_card_details;
        onSubmit({ ...data, creditCard, ...terms }, onFailure);
      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error:', error); // Handle errors
      });
  };

  const initializeCommerceHub = () => {
    setError('');

    if (!window?.fiserv.commercehub.createPaymentForm) {
      throw new Error('Commerce Hub methods are not in the window');
    }
    window.fiserv.commercehub.createPaymentForm(commerceHubConfigs)
      .then((pForm: any) => setPaymentForm(pForm))
      .catch(() => { setError(UNRECOVERABLE_ERROR); purchaseComplete(UNRECOVERABLE_ERROR); });
  };

  const onCommerceHubSubmit = (checkoutTerms: any) => {
    showSpinner();
    const linkInfo = sessionStorage.getItem('link');
    const billingInfo = sessionStorage.getItem('billingAddressResponse');
    let link = null;
    let secureCreds: any = null;
    if (linkInfo) {
      link = JSON.parse(linkInfo);
    }

    if (billingInfo) {
      ({ secureCreds } = JSON.parse(billingInfo));
    }

    const { serviceAddress, companyId } = link;
    const { accessToken, keyId, publicKeyBase64 } = secureCreds;

    const merchantIds: string[] = merchantId.split('|');

    const setMerchantId = (companyId: string) => {
      const convertedCompanyId = companyId.toLowerCase();
      switch (true) {
        case convertedCompanyId === 'il03':
          return merchantIds[0];
        case convertedCompanyId === 'il04':
          return merchantIds[1];
        case convertedCompanyId === 'il05':
          return merchantIds[2];
        default:
          return merchantIds[0];
      }
    };
    try {

      paymentForm.submit({
        accessToken: accessToken,
        apiKey: apiKey,
        keyId: keyId,
        merchantId: setMerchantId(companyId),
        publicKey: publicKeyBase64,
        terminalId: terminalId
      }).then((resolve: any) => {
        // eslint-disable-next-line no-console
        console.log('Payment submission response', resolve);
        makeVerificationApiCall(secureCreds, serviceAddress, setMerchantId(companyId), checkoutTerms, onSubmit);

      }).catch((error: any) => {
        // eslint-disable-next-line no-console
        console.log('Payment submission error', error);
        setError(`Payment submission error ${error}`);

      });
    } catch {
      setError(UNRECOVERABLE_ERROR);
      purchaseComplete(UNRECOVERABLE_ERROR);
      hideSpinner();
    }
  };

  return {
    initializeCommerceHub,
    onCommerceHubSubmit
  };
};
