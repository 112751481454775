import 'src/components/modals/error/index.scss';
import React from 'react';

interface Props {
  error?: any;
}

/* eslint-disable max-len */
export default ({ error = {} }: Props) => (
  <div className='modal-error'>
    <div className='modal-error--header'>Error</div>
    <div className='modal-error--message'>
      {error?.code == '500000' ? 'Sorry, the promo code you entered is invalid or expired. Please enter a valid promo code.' : error?.message || 'Something must have gone wrong. Please refresh the page or try again.'}
      {error?.link && <a href={error.link}>here</a>}
    </div>
  </div>
);