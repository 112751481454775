import React, { useState } from 'react';

interface Props {
  alt?: string;
  className?: string;
  src?: string;
}

export default ({ src, alt, className }: Props) => {
  const [ hasError, setHasError ] = useState(!src);

  return (<>
    {!hasError &&
      <img className={className} src={src} alt={alt} onError={() => setHasError(true)} />
    }
  </>);
};