import 'src/components/atoms/spinner/index.scss';
import { useSpinner, useTheme } from 'src/hooks';
import React from 'react';

export default () => {
  const { isVisible } = useSpinner();
  const { backgroundColor } = useTheme('primary');

  return (
    <div className='spinner' style={{ visibility: isVisible ? 'visible' : 'hidden' }}>
      <div className='spinner--animation' style={{ borderTopColor: backgroundColor }}/>
    </div>
  );
};