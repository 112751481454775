export const toAddServiceAddressRequest = (data: any = {}, { plansInCart = [] } = {}) => {
  sessionStorage.setItem('serviceInfo', JSON.stringify(data));

  return ({
    address: {
      address1: data.address1,
      address2: data.address2,
      city: data.city,
      state: data.state,
      zipcode: data.zipcode
    },
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName,
    phoneNumber: data.phone?.replace(/[^\d]/g, ''),
    plans: plansInCart,
    promoCode: sessionStorage.getItem('promoCode')
  });
};

export const toAddBillingAddressRequest = (data: any = {}, linkBody: any = {}) => {
  sessionStorage.setItem('link', JSON.stringify(linkBody._link));

  return ({
    ...linkBody,
    address: {
      ...linkBody.address,
      ...data
    }
  }

  );
};

export const toRetryAddressRequest = (data: any = {}, linkBody: any = {}) => ({
  ...linkBody,
  address: {
    ...linkBody.address,
    ...data
  }
});

export const toPurchaseRequest = (data: any = {}, linkBody: any = {}) => {

  const { accountNumber, accountType, confirmAccountNumber, routingNumber, termDefault, term0, term1, term2, ...restData } = data;
  const bankAccount = routingNumber && { accountNumber, accountType, confirmAccountNumber, routingNumber };

  return {
    ...linkBody,
    ...restData,
    ...(bankAccount && { bankAccount }),
    terms: [ termDefault, term0, term1, term2 ]
  };
};

export const toMaintenanceRequest = (data: any = {}, linkBody: any = {}) => ({
  ...linkBody,
  ...data
});

export const toExpressCheckoutRequest = (linkBody: any = {}) => ({
  ...linkBody
});

export const toExpressCheckoutValidationRequest = (data: any = {}, linkBody: any = {}) => ({
  ...linkBody,
  ...data
});
