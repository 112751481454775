import React, { ReactNode, createContext, useContext, useEffect } from 'react';
import { useConfig } from 'src/hooks';

type Theme = {
  backgroundColor: string;
  color: string;
  accentColor: string;
}

const initial = {
  'black': { backgroundColor: '#000000', color: '#000000' },
  'footer': { backgroundColor: '#353C41', color: '#000000' },
  'gray': { backgroundColor: '#D7D7D7', color: '#000000' },
  'planCardCaseLabel': { backgroundColor: '#5C27A1 ', color: '#000000' },
  'primary': { backgroundColor: '#D7D7D7', color: '#000000' },
  'secondary': { backgroundColor: '#D7D7D7', color: '#000000' },
  'tertiary': { backgroundColor: '#D7D7D7', color: '#000000' }
};

const ThemeContext = createContext({});

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const { theme = initial } = useConfig();

  useEffect(() => {
    document.body.style.setProperty('--background-color-primary', theme.primary.backgroundColor);
  }, [ theme ]);

  return (<ThemeContext.Provider value={{ ...theme }}> {children} </ThemeContext.Provider>);
};

export const useTheme = (theme: keyof typeof initial | undefined) => {
  const context: Record<string, Theme> = useContext(ThemeContext);

  return context?.[theme ?? ''] ?? {};
};

export default ThemeContext;
