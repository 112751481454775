import 'src/components/atoms/progress-bar/index.scss';
import React from 'react';
import { useTheme } from 'src/hooks';

const ProgressBar = ({ progressComplete = 0 }: any ) => {
  const { backgroundColor } = useTheme('primary');

  const completedStyle = {
    backgroundColor,
    width: `${progressComplete}%`
  };

  const uncompletedStyle = {
    backgroundColor,
    width: `${100 - progressComplete}%`
  };

  return (
    <div className='progress-bar'>
      <span style={completedStyle}></span>
      <span className='progress-bar--uncompleted' style={uncompletedStyle}></span>
    </div>
  );
};

export default ProgressBar;