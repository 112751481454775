import 'src/components/atoms/overlay/index.scss';
import { useModal, useSpinner } from 'src/hooks';
import React from 'react';

export default () => {
  const { isVisible: modalVisible } = useModal();
  const { isVisible: spinnerVisible } = useSpinner();

  const display = modalVisible || spinnerVisible ? 'block' : 'none';
  const className = spinnerVisible ? 'overlay--spinner-active' : '';

  return (<div style={{ display }} className={`overlay ${className}`} />);
};
