/* eslint-disable max-len */
import React, { createContext, useContext, useMemo, useReducer } from 'react';
import { BILLING_FREQUENCY } from 'src/constants';
import { pushCustomEvent } from 'plugins/custom-plugin-adobe-launch';
import { toCartEvent } from 'src/helpers/transformers/adobe';

import type { BillingFrequency, ClearCart, EnableDiscountForm, GetCartTotal, GetDiscount, GetPlansInCart, UpdateCart } from 'src/context/cart/_types';
import type { Children, EmptyFunction } from 'src/_types';
import type { Plan } from 'src/helpers/transformers/api/_types';
import { useApiState } from 'src/hooks';

import { v4 as uuidv4 } from 'uuid';

const { ANNUAL, MONTHLY } = BILLING_FREQUENCY;

interface Action {
  type: string;
  plan?: any;
  amount?: number;
  billingFrequency?: any;
  shouldEnableDiscountForm?: boolean;
}

interface State {
  billingFrequency: BillingFrequency;
  cart: {
    [id: string]: Plan & { quantity: number };
  };
  shouldShowBadge: boolean;
  shouldEnableDiscountForm: boolean;
}

const CartContext = createContext({});

const initial = { billingFrequency: MONTHLY, cart: {}, shouldEnableDiscountForm: false, shouldShowBadge: false };

const reducer = (state = initial, { amount = 1, billingFrequency, shouldEnableDiscountForm = false, plan, type }: Action) => {
  switch (type) {
    case 'CLEAR_CART': return initial;
    case 'ENABLE_DISCOUNT_FORM': return ({ ...state, shouldEnableDiscountForm });
    case 'HIDE_BADGE': return ({ ...state, shouldShowBadge: false });
    case 'SET_BILLING_FREQUENCY': return ({ ...state, billingFrequency });
    case 'UPDATE_CART': return ({
      ...state,
      cart: {
        ...state.cart,
        [plan.id]: { ...plan, quantity: amount }
      },
      shouldShowBadge: true
    });
    default: return state;
  }
};

export const CartProvider = ({ children }: Children) => {
  const [ state, dispatch ] = useReducer(reducer, initial);

  const clearCart: ClearCart = () => dispatch({ type: 'CLEAR_CART' });
  const enableDiscountForm: EnableDiscountForm = (shouldEnableDiscountForm: boolean) => dispatch({ shouldEnableDiscountForm, type: 'ENABLE_DISCOUNT_FORM' });
  const hideBadge: EmptyFunction = () => dispatch({ type: 'HIDE_BADGE' });
  const setBillingFrequency = (billingFrequency: any) => dispatch({ billingFrequency, type: 'SET_BILLING_FREQUENCY' });
  const updateCart: UpdateCart = ({ amount, plan }) => {
    let cartId: string = sessionStorage.getItem('cartId') || '';
    if (Number(sessionStorage.getItem('plansInCart')) === 0) {
      cartId = uuidv4().slice(0, 16);
      sessionStorage.setItem('cartId', cartId);
    }
    pushCustomEvent(toCartEvent(amount, plan, (state.cart as any)[plan.id], cartId));
    dispatch({ amount, plan, type: 'UPDATE_CART' });
  };

  const value = useMemo(() => ({
    ...state, clearCart, enableDiscountForm, hideBadge, setBillingFrequency, updateCart
  }), [ state, clearCart, enableDiscountForm, hideBadge, setBillingFrequency, updateCart ]);

  return (<CartContext.Provider value={value}>{children} </CartContext.Provider>);
};

const round = (value: number, decimals: number) => Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals).toFixed(decimals);

const getDiscount: GetDiscount = ({ context, discount }) => (discount && round((context.billingFrequency === 'annual' ? discount.annualAmount : discount.monthlyAmount), 2));

const getPlansInCart: GetPlansInCart = ({ context, rate }) => Object.keys(context.cart ?? {})
  .map(id => context.cart?.[id])
  .filter(plan => plan?.quantity && plan?.price)
  .map(plan => ({
    ...plan,
    price: {
      ...plan?.price,
      formattedAmount: context.billingFrequency === ANNUAL ? `${plan?.price?.formattedAnnualAmount}${rate}` : `${plan?.price?.formattedMonthlyAmount}${rate}`
    }
  }));

const getCartTotal: GetCartTotal = ({ totals, context, plansInCart }) => {
  const plans: Array<Plan> = plansInCart;

  const calculatedTotalFromCart = plans
    .map(({ price, quantity = 0 }) => ((context.billingFrequency === ANNUAL ? price?.annualAmount : price?.monthlyAmount) * quantity))
    .reduce((sum, x) => (sum + x), 0);

  const totalFromApi = context.billingFrequency === ANNUAL ? totals?.annualAmount : totals?.monthlyAmount;
  const total = totalFromApi || calculatedTotalFromCart;

  return round(total, 2);
};

const getFormattedPlansInCart = (plansInCart: any) => plansInCart
  .map((plan: Plan) => plan?.name)
  .reduce((all: string, current: string, index: number, array: Array<Plan>) => {
    if (index === 0) { return current; }

    const delimiter = index < array.length - 1 ? ', ' : ' and ';

    return `${all}${delimiter}${current}`;
  }, '');

export const useCart = () => {
  const context: Partial<State> = useContext(CartContext);

  const { discount, totals } = useApiState();

  const rate = context.billingFrequency === ANNUAL ? '/yr' : '/mo';

  const plansInCart = getPlansInCart({ context, rate });
  if (typeof window !== 'undefined') {
    sessionStorage.setItem('plansInCart', plansInCart.length);
  }

  return {
    ...initial,
    clearCart: () => undefined,
    enableDiscountForm: () => undefined,
    hideBadge: () => undefined,
    setBillingFrequency: () => undefined,
    updateCart: () => undefined,
    ...context,
    cartTotal: getCartTotal({ context, plansInCart, totals }),
    discount: getDiscount({ context, discount }),
    formattedPlansInCart: getFormattedPlansInCart(plansInCart),
    isCartEmpty: !plansInCart.length,
    plansInCart,
    rate
  };
};

export default CartContext;
