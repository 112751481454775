import type { CustomEvent, PageViewData, PlansEvent, ZipcodeEvent } from './_types';

export const DATA_LAYER_NAME = 'adobeDataLayer';

const getBrand = () => location.hostname?.split('.')?.reverse()?.[1] ?? '';

const push = (event: any) => (window as any)?.[DATA_LAYER_NAME]?.push?.(event);

export const pushPageView = (pathname = '', pageViewData: PageViewData = {}) => push({
  event: 'pageView',
  messages: { error: '', siteWide: '' },
  page: {
    brand: getBrand(),
    language: 'en',
    pageDetail: '',
    pageExtraDetail: '',
    salesAgentPartnerId: '',
    salesAgentVendor: '',
    siteSection: 'nrgprotects',
    subSection1: 'sales',
    subSection2: 'shop',
    ...pageViewData[pathname]
  }
});

export const pushCustomEvent = ({ cart, event, product }: CustomEvent) => push({
  event,
  global: { brand: getBrand(), eventAction: event },
  product,
  ...(cart && { cart })
});

export const pushViewAllPlansEvent = ({ event, eventInfo, userInfo }: PlansEvent) => push({
  event,
  eventInfo,
  global: { brand: getBrand() },
  userInfo
});

export const pushViewAllCloseEvent = ({ event, linkInfo }: ZipcodeEvent) => push({
  event,
  global: { brand: getBrand() },
  linkInfo
});

export const pushNavigationEvent = ({ event, navigationDetail }: any) => push({
  event,
  global: { brand: getBrand() },
  navigationDetail
});

export const pushMessageViewEvent = ({ event, messages }: any) => push({
  event,
  global: { brand: getBrand() },
  messages
});
export const pushZipCodeEvent = ({ event, linkInfo, userInfo }: ZipcodeEvent) => push({
  event,
  global: { brand: getBrand() },
  linkInfo,
  userInfo
});
