import React from 'react';

interface Props {
  className?: string;
  fill?: string;
  height?: string;
  onClick?: () => void;
  width?: string;
}

export default ( { className, fill = 'black', height = '18', onClick, width = '18' }: Props ) => (
  /* eslint-disable max-len */
  <svg className={className} onClick={onClick} width={width} height={height} viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M1.66674 1.33337H10.3334C10.4294 1.36705 10.5164 1.42225 10.5878 1.49475C10.6592 1.56725 10.7131 1.65513 10.7452 1.75165C10.7774 1.84818 10.7871 1.95079 10.7735 2.05162C10.7598 2.15245 10.7233 2.24883 10.6667 2.33337L7.3334 6.00004V10.6667L4.66674 8.66671V6.00004L1.3334 2.33337C1.2768 2.24883 1.2403 2.15245 1.22669 2.05162C1.21309 1.95079 1.22274 1.84818 1.25491 1.75165C1.28709 1.65513 1.34093 1.56725 1.41231 1.49475C1.4837 1.42225 1.57073 1.36705 1.66674 1.33337Z' stroke={fill} strokeWidth='0.916667' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
