import { useApiState } from 'src/hooks';
import { useEffect } from 'react';

const KEY = 'config';

const getSessionConfig = () => {
  try {
    const item = sessionStorage?.getItem(KEY) ?? `""`;

    return JSON.parse(item);
  } catch {} // eslint-disable-line no-empty
};

export default () => {
  const { _meta: { config } = {} } = useApiState();
  const sessionConfig = getSessionConfig();

  useEffect(() => {
    const hasConfig = Object.keys(config ?? {}).length;

    hasConfig && !sessionConfig && sessionStorage?.setItem(KEY, JSON.stringify(config));
  }, [ config ]);

  return { ...sessionConfig, ...config };
};
