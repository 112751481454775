import AddressValidationModal from 'src/components/modals/address';
import ErrorModal from 'src/components/modals/error';
import { navigate } from 'gatsby';

import React from 'react';
import SearchModal from 'src/components/modals/search';

import { PAGES } from 'src/constants';
import { pushZipCodeEvent } from 'plugins/custom-plugin-adobe-launch';
import { useApi } from 'src/hooks';

const { SHOPPING } = PAGES;

interface Props {
  error: any,
  onComplete: any,
  onRetry: any
}

export default ({ error, onComplete, onRetry }: Props) => {
  const { getPlans } = useApi();

  switch (error.code) {
    case '400100':
      localStorage.setItem('ZipCodeModal', 'true');

      return (<SearchModal onSubmit={(data: any) => {
        getPlans(data, () => {
          if (data?.zipcode) {
            pushZipCodeEvent({
              event: 'linkClick',
              linkInfo:
              {
                linkAction: 'enterZip',
                linkName: 'View Plans',
                linkType: 'other',
                linkURL: SHOPPING
              },
              userInfo:
              {
                zipCode: data.zipcode
              }
            });
          }
          const queryParams = sessionStorage.getItem('queryParams');
          if (queryParams) {
            navigate(SHOPPING + queryParams, { replace: true });
          } else {
            navigate(SHOPPING);
          }
        }).catch(() => { navigate(SHOPPING); });
      }} />);
    case '400204':
    case '400205':

      return (<AddressValidationModal error={error} onComplete={onComplete} onRetry={onRetry} />);
    default:
      localStorage.setItem('ZipCodeModal', 'true');

      return (<ErrorModal error={error} />);
  }
};