import React, { createContext, useState } from 'react';

export const EnrollmentContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  setSharedState: (context: any) => {},
  sharedState: {
    checkoutId: '',
    expressCheckoutData: null,
    isExpressCheckout: false,
    quotationId: null,
    userAddress: null
  }
});

export const ContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [ sharedState, setSharedState ] = useState({
    checkoutId: '',
    expressCheckoutData: null,
    isExpressCheckout: false,
    quotationId: null,
    userAddress: null
  });

  return (
    <EnrollmentContext.Provider value={{ setSharedState, sharedState }}>
      {children}
    </EnrollmentContext.Provider>
  );
};