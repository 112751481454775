import React from 'react';

interface Props {
  className?: string;
  fill?: string;
  height?: string;
  width?: string;
}

export default ({ className, fill = '#707070', height = '24', width = '24' }: Props) => (
  /* eslint-disable max-len */
  <svg className={className} width={height} height={width} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z' stroke={fill} strokeWidth='0.916667' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M9 12L11 14L15 10' stroke={fill} strokeWidth='0.916667' strokeLinecap='round' strokeLinejoin='round'/>
  </svg>

);