import 'src/components/atoms/badge/index.scss';
import React from 'react';
import { useTheme } from 'src/hooks';

interface Props {
  label: string;
  theme?: 'primary' | 'secondary' | 'tertiary' | 'gray';
}

export default ({ label, theme = 'primary' }: Props) => {
  const { backgroundColor, accentColor } = useTheme(theme);

  return (
    <div className='badge' style={{ backgroundColor: accentColor ? `${accentColor}` : `${backgroundColor}40` }}>
      <span className='badge--icon' style={{ backgroundColor }} />
      <span className='badge--label'>{label}</span>
    </div>
  );
};
