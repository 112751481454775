import React, { ReactNode, createContext, useContext, useEffect, useReducer } from 'react';
import { LINKS } from 'src/constants';

import type { Discount, Totals } from 'src/context/apiState/_types';
import type { _Links, _Meta } from 'src/hooks/useApi/_types';

const { ALLIED_CSWB_PLANS, ALLIED_CHECKOUT_VALIDATION, GET_PLANS, MAINTENANCE_PAGE, EXPRESS_CHECKOUT, EXPRESS_CHECKOUT_VALIDATION } = LINKS;

type Action = {
  response?: any;
  type: string;
};

interface State {
  address?: {
    address1?: string;
    address2: string;
    city?: string;
    state?: string;
    zipcode?: string;
  };
  confirmationNumber?: number;
  email?: string;
  plans: {id: string, quantity?: number, category_name?: string}[];
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  saveApiState: (response: any) => void;
  _links: _Links;
  _meta?: _Meta;
  discount?: Discount;
  totals?: Totals;
  quotationId?: any;
  paymentJs?: any;
  paymentTerms?: any;
  commerceHub?: any;
}

const ApiStateContext = createContext({});

const initial = {
  _links: {
    [ALLIED_CHECKOUT_VALIDATION]: { href: '/fetch-cache-data', method: 'POST' as const },
    [ALLIED_CSWB_PLANS]: { href: '/allied-cswb-plans', method: 'POST' as const },
    [EXPRESS_CHECKOUT]: { href: '/shopping', method: 'POST' as const },
    [EXPRESS_CHECKOUT_VALIDATION]: { href: '/shopping', method: 'POST' as const },
    [GET_PLANS]: { href: '/plans', method: 'GET' as const },
    [MAINTENANCE_PAGE]: { href: '/maintenance', method: 'GET' as const }
  }
};

const reducer = (state = initial, { response = {}, type }: Action) => {
  const { _links, ...rest } = response;

  switch (type) {
    case 'CLEAR_API_STATE': return initial;
    case 'SAVE_API_STATE': return { ...state, ...rest, _links: { ...state._links, ..._links } };
    default: return { ...state, ...initial };
  }
};

export const ApiStateProvider = ({ children }: { children: ReactNode }) => {
  const [ state, dispatch ] = useReducer(reducer, initial);

  const clearApiState = () => dispatch({ type: 'CLEAR_API_STATE' });
  const saveApiState = (response = {}) => dispatch({ response, type: 'SAVE_API_STATE' });

  useEffect(() => {
    state.zipcode && localStorage.setItem('zipcode', state.zipcode);
  }, [ state.zipcode ]);

  return (<ApiStateContext.Provider value={{ ...state, clearApiState, saveApiState }}> {children} </ApiStateContext.Provider>);
};

export const useApiState = () => {
  const context: Partial<State> = useContext(ApiStateContext);

  return {
    ...initial,
    clearApiState: () => undefined,
    saveApiState: () => undefined,
    ...context
  };
};

export default ApiStateContext;
