import React from 'react';

interface Props {
  fill?: string;
  height?: string;
  width?: string;
}

export default ({ fill = 'black', height = '6.47', width = '11.36' }: Props) => (
  /* eslint-disable max-len */
  <svg width={width} height={height} viewBox='0 0 12 8' fill={fill} xmlns='http://www.w3.org/2000/svg'>
    <path d='M5.99985 0.924942C6.09985 0.924942 6.19152 0.941609 6.27485 0.974943C6.35818 1.00828 6.44152 1.06661 6.52485 1.14994L11.4749 6.09994C11.6082 6.23328 11.6707 6.41244 11.6624 6.63744C11.654 6.86244 11.5832 7.04161 11.4499 7.17494C11.2832 7.34161 11.104 7.41244 10.9124 7.38744C10.7207 7.36244 10.5499 7.28328 10.3999 7.14994L5.99985 2.74994L1.59985 7.14994C1.46652 7.28327 1.28735 7.35828 1.06235 7.37494C0.83735 7.39161 0.658183 7.31661 0.52485 7.14994C0.358183 7.01661 0.287351 6.84161 0.31235 6.62494C0.33735 6.40828 0.416516 6.22494 0.54985 6.07494L5.47485 1.14994C5.55819 1.06661 5.64152 1.00828 5.72485 0.974943C5.80819 0.941609 5.89985 0.924942 5.99985 0.924942Z' fill={fill}/>
  </svg>

);
