import type { Plan } from 'src/helpers/transformers/api/_types';
import type { Product } from 'plugins/custom-plugin-adobe-launch/_types';

export const toProduct = (plan: Plan): Product => ({
  cancelReturnFee: '',
  category: 'ANCILLARY SERVICE',
  discount: '',
  flags: plan.promotion ?? '',
  gridPosition: '',
  name: plan.name ?? '',
  planRateType: 'FIXED',
  price: `${plan.price?.monthlyAmount ?? ''}`,
  priceTotal: `${plan.price?.monthlyAmount * (plan.quantity ?? 1) ?? ''}`,
  quantity: `${plan.quantity ?? '0'}`,
  sku: plan.id ?? '',
  term: '12',
  type: plan.category ?? ''
});

export const toCartEvent = (quantity: number, plan: Plan, planInCart: Plan, cartId: string) => {
  const CART_ADD = 'cartAdd' as const;
  const CART_REMOVE = 'cartRemove' as const;

  const previousQuantity = planInCart?.quantity ?? 0;
  const updatedQuantity = quantity - previousQuantity;

  return {
    cart: { id: cartId, quantity: `${Math.abs(updatedQuantity)}` },
    event: updatedQuantity > 0 ? CART_ADD : CART_REMOVE,
    product: toProduct({ ...plan, quantity: Math.abs(updatedQuantity) })
  };
};