/* eslint-disable sort-keys */
export const AddressValidation = () => ({
  correctAddress: {
    required: { message: 'Confirm this address is correct', value: true }
  }
});

export const NewAddressValidation = () => ({
  address1: {
    required: { message: 'Service Address is required', value: true }
  },
  city: {
    pattern: { message: 'Enter city name using only letters', value: /^[a-zA-Z]/ },
    required: { message: 'City is required', value: true }
  },
  zipcode: {
    required: { message: 'Zip code is required', value: true }
  },
  state: {
    pattern: { message: 'Enter state', value: /^[a-zA-Z]/ },
    required: { message: 'State is required', value: true }
  }
});