import React from 'react';

interface Props {
  fill?: string;
  height?: string;
  onClick?: () => void;
  width?: string;
  className?: string;
}

export default ({ className, fill = 'black', height = '18', onClick, width = '18' }: Props) => (
  /* eslint-disable max-len */
  <svg className={className} style={{ cursor: 'pointer', flexShrink: '0' }} height={height} width={width} onClick={onClick} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M3 5.25H15' stroke={fill} strokeWidth='0.916667' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M7.5 8.25V12.75' stroke={fill} strokeWidth='0.916667' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M10.5 8.25V12.75' stroke={fill} strokeWidth='0.916667' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M3.75 5.25L4.5 14.25C4.5 14.6478 4.65804 15.0294 4.93934 15.3107C5.22064 15.592 5.60218 15.75 6 15.75H12C12.3978 15.75 12.7794 15.592 13.0607 15.3107C13.342 15.0294 13.5 14.6478 13.5 14.25L14.25 5.25' stroke={fill} strokeWidth='0.916667' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M6.75 5.25V3C6.75 2.80109 6.82902 2.61032 6.96967 2.46967C7.11032 2.32902 7.30109 2.25 7.5 2.25H10.5C10.6989 2.25 10.8897 2.32902 11.0303 2.46967C11.171 2.61032 11.25 2.80109 11.25 3V5.25' stroke={fill} strokeWidth='0.916667' strokeLinecap='round' strokeLinejoin='round'/>
  </svg>
);
