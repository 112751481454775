/* eslint-disable max-len */
import React from 'react';

interface Props {
  fill?: string;
  height?: string;
  width?: string;
}

export default ({ fill = 'black', height = '24', width = '24' }: Props) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M6 21C7.10457 21 8 20.1046 8 19C8 17.8954 7.10457 17 6 17C4.89543 17 4 17.8954 4 19C4 20.1046 4.89543 21 6 21Z' stroke='black' strokeWidth='0.916667' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M17 21C18.1046 21 19 20.1046 19 19C19 17.8954 18.1046 17 17 17C15.8954 17 15 17.8954 15 19C15 20.1046 15.8954 21 17 21Z' stroke='black' strokeWidth='0.916667' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M17 17H6V3H4' stroke='black' strokeWidth='0.916667' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M6 5L20 6L19 13H6' stroke={fill} strokeWidth='0.916667' strokeLinecap='round' strokeLinejoin='round'/>
  </svg>
);