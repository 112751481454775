import React from 'react';

interface Props {
  fill?: string;
  height?: string;
  width?: string;
}

export default ({ fill = 'black', height = '6.47', width = '11.36' }: Props) => (
  /* eslint-disable max-len */
  <svg width={width} height={height} viewBox='0 0 12 8' fill={fill} xmlns='http://www.w3.org/2000/svg'>
    <path d='M6.00015 7.075C5.90015 7.075 5.80848 7.05833 5.72515 7.025C5.64182 6.99166 5.55848 6.93333 5.47515 6.85L0.525149 1.9C0.391816 1.76666 0.329316 1.5875 0.337649 1.3625C0.345983 1.1375 0.416816 0.958329 0.550149 0.824996C0.716816 0.658329 0.895983 0.587496 1.08765 0.612496C1.27932 0.637496 1.45015 0.716663 1.60015 0.849996L6.00015 5.25L10.4001 0.849996C10.5335 0.716663 10.7126 0.641663 10.9376 0.624996C11.1627 0.608329 11.3418 0.68333 11.4752 0.849996C11.6418 0.98333 11.7126 1.15833 11.6876 1.375C11.6627 1.59166 11.5835 1.775 11.4502 1.925L6.52515 6.85C6.44182 6.93333 6.35848 6.99166 6.27515 7.025C6.19182 7.05833 6.10015 7.075 6.00015 7.075Z' fill={fill}/>
  </svg>

);
