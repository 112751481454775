import { useCallback, useEffect, useState } from 'react';

export default () => {
  const [ width, setWidth ] = useState(0);

  const onResize = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    setWidth(window.innerWidth);

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  return {
    isDesktop: width >= 1200
  };
};
