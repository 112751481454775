import { graphql, useStaticQuery } from 'gatsby';

export default () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            apiUrl
            description
            title
          }
        }
      }
    `
  );

  return site.siteMetadata;
};
