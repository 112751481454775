import 'src/components/atoms/checkbox/index.scss';
import Icon from 'src/components/atoms/icon';
import React from 'react';

interface Props {
  checked?: boolean;
  children?: any;
  formProps?: {
    error?: { message?: string };
  };
  label: string;
  name?: string;
  onClick?: any;
  value?: string;
}

export default({ checked = false, children, formProps, label, name, onClick, value }: Props) => (
  <div className='checkbox'>
    <input
      aria-label={name}
      className='checkbox--input'
      defaultChecked={checked}
      {...formProps}
      id={value}
      name={name}
      onClick={onClick}
      role='input'
      type='checkbox'
      value={value}
    />
    <label className='checkbox--label' htmlFor={value}>{label}</label>
    {children}
    {formProps?.error?.message &&
      <label className='checkbox--label--error'>
        <Icon type='error' className='checkbox--label--error--icon' /> {formProps.error.message}
      </label>
    }
  </div>
);