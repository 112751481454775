import 'src/components/atoms/radio/index.scss';
import React from 'react';

interface Props {
  checked?: boolean;
  formProps?: {
    error?: { message?: string };
  };
  label?: string;
  name: string;
  value?: string;
  onClick?: any;
}

export default ({ checked, formProps, label, name, onClick, value }: Props) => (
  <div className='radio'>
    <input
      aria-label={label}
      className='radio--input'
      defaultChecked={checked}
      {...formProps}
      id={value}
      name={name}
      onClick={() => onClick?.(value)}
      role='input'
      type='radio'
      value={value}
    />
    <label className='radio--label' htmlFor={value}>{label}</label>
  </div>
);
