import 'src/components/layout/main/index.scss';
import React, { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  className?: string;
}

export default ({ children, className }: Props) => (
  <main className={className}>
    <div className='main'>{children}</div>
  </main>
);
