import 'src/components/layout/header/index.scss';
import { useConfig, useTheme, useUrl } from 'src/hooks';
import Icon from 'src/components/atoms/icon';
import Logo from 'src/components/atoms/logo';
import ProgressBar from 'src/components/atoms/progress-bar';
import React from 'react';
import { pushNavigationEvent } from 'plugins/custom-plugin-adobe-launch';

interface Props {
  progressComplete?: number;
}

export default ({ progressComplete = 0 }: Props) => {
  const { logo, overrideStyles, tollFreeNumber } = useConfig();

  const { backgroundColor } = useTheme('black');

  let phoneNumber = tollFreeNumber?.default;

  if (typeof window !== 'undefined') {
    const { getQueryParams } = useUrl();
    let { sps, campaign } = getQueryParams();

    if (sessionStorage.getItem('sps')) {
      sps = sessionStorage.getItem('sps') || '';
    }

    if (sps && tollFreeNumber?.sps) {
      Object.keys(tollFreeNumber.sps).forEach(function (key) {
        if (key.toUpperCase() === sps.toString().toUpperCase()) {
          phoneNumber = tollFreeNumber.sps[key];
        }
      });
    }

    if (sessionStorage.getItem('campaign')) {
      campaign = sessionStorage.getItem('campaign') || '';
    }

    if (campaign && tollFreeNumber?.campaign) {
      Object.keys(tollFreeNumber.campaign).forEach(function (key) {
        if (key.toUpperCase() === campaign.toString().toUpperCase()) {
          phoneNumber = tollFreeNumber.campaign[key];
        }
      });
    }
  }

  const makePhoneCall = (() => {

    pushNavigationEvent({
      event: 'navigation',
      navigationDetail: {
        navLocation: 'Global',
        navTitle: phoneNumber.link.toString() + 'to call us'
      }
    });
    window.location.href = phoneNumber.link;
  });

  const CallLink = () => (
    <a className='header--call-link' onClick={() => makePhoneCall()}>
      <Icon color={backgroundColor} type='phone' />
      <span className={`header--call-link--number ${overrideStyles?.hyperLinkClass}`}>{phoneNumber.formatted}</span>
    </a>
  );

  return (
    <header>
      <div className='header'>
        {logo && <Logo src={logo} />}
        {phoneNumber && <CallLink />}
      </div>
      <ProgressBar progressComplete={progressComplete} />
    </header>
  );
};
