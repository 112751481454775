import React from 'react';

interface Props {
  fill?: string;
  height?: string;
  width?: string;
}

export default ({ fill = 'black', height = '18', width = '14' }: Props) => (
  /* eslint-disable max-len */
  <svg width={width} height={height} viewBox='0 0 14 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M6.99992 9.00008C6.08325 9.00008 5.33325 8.25008 5.33325 7.33341C5.33325 6.41675 6.08325 5.66675 6.99992 5.66675C7.91658 5.66675 8.66658 6.41675 8.66658 7.33341C8.66658 8.25008 7.91658 9.00008 6.99992 9.00008ZM11.9999 7.50008C11.9999 4.47508 9.79158 2.33341 6.99992 2.33341C4.20825 2.33341 1.99992 4.47508 1.99992 7.50008C1.99992 9.45008 3.62492 12.0334 6.99992 15.1167C10.3749 12.0334 11.9999 9.45008 11.9999 7.50008ZM6.99992 0.666748C10.4999 0.666748 13.6666 3.35008 13.6666 7.50008C13.6666 10.2667 11.4416 13.5417 6.99992 17.3334C2.55825 13.5417 0.333252 10.2667 0.333252 7.50008C0.333252 3.35008 3.49992 0.666748 6.99992 0.666748Z' fill={fill} />
  </svg>
);
