/* eslint-disable max-len */
import 'src/components/molecules/limitations/index.scss';
import React from 'react';
import Separator from 'src/components/atoms/separator';

interface Props {
  limitations?: Array<string>;
}

export default ({ limitations }: Props) => (
  <>
    {limitations && <>
      <Separator />
      <div className='limitations'>
        {limitations.map((limitation, index) => (<span key={index}>{limitation}</span>))}
      </div>
    </>}
  </>
);