import 'src/components/atoms/input/index.scss';
import React, { KeyboardEvent, WheelEvent, useState } from 'react';
import { COMMERCE_HUB_PAYMENT_SELECTORS } from 'src/constants';
import Icon from 'src/components/atoms/icon';

interface Props {
  className?: string;
  disabled?: boolean;
  errorText?: string;
  formProps?: {
    error?: { message?: string };
  };
  icon?: 'pin';
  inputMode?: 'numeric';
  label?: string;
  maxLength?: number;
  name?: string;
  isAllied?: boolean;
  options?: Array<{
    label: string;
    value: string;
  }>
  placeholder?: string;
  secondaryLabel?: string;
  type?: 'text' | 'number' | 'tel' | 'email' | 'select';
  id?: string;
  dataLabel?: string
;}

const getNumberProps = () => {
  const validKeys = [ ...'1234567890'.split(''), 'ArrowLeft', 'ArrowRight', 'Backspace', 'Enter', 'Tab' ];

  return {
    onKeyDown: (event: KeyboardEvent) => (!validKeys.includes(event.key) && event.preventDefault?.()),
    onWheel: ({ target }: WheelEvent) => (target as HTMLInputElement)?.blur?.()
  };
};

export default ({
  className = '',
  disabled = false,
  isAllied = false,
  errorText = '',
  formProps,
  icon,
  inputMode,
  label,
  maxLength,
  name = '',
  options,
  placeholder,
  secondaryLabel = '',
  type = 'text',
  id,
  dataLabel,
  ...restProps
}: Props) => {
  const error = formProps?.error?.message;
  const [ emailError, setEmailError ] = useState<string | null>(label === 'Email' && errorText ? errorText : null);

  const handleKeyDown = () => {
    if (label === 'Email') {
      setEmailError('');
    }
  };

  const isPaymentInput = Object.values(COMMERCE_HUB_PAYMENT_SELECTORS).some(selector => Object.keys(restProps).includes(selector));
  if (isAllied) {
    disabled = true;
  }

  const Label = () => (
    <div className='input--label'>
      {label && <label className='input--label--name'> {label} </label>}
      {secondaryLabel && <label className='input--label--secondary-label'>{secondaryLabel}</label>}
      {error && <label className='input--label--error'>
        <Icon type='error' className='input--label--error--icon' /> {error}
      </label>}
      {emailError && <label className='input--label--error'>
        <Icon type='error' className='input--label--error--icon' /> {emailError}
      </label>}
    </div>
  );

  const Input = () => (
    <div className='input--input' id={id} data-label={dataLabel}>
      {icon && <span className='input--input--icon'><Icon type={icon} /></span>}
      <input
        aria-label={name}
        className={`input--input--input ${error ? 'invalid' : ''}`}
        disabled={disabled}
        maxLength={maxLength}
        placeholder={placeholder}
        role='input'
        type={type}
        onKeyDown={handleKeyDown}
        {...formProps}
        {...((type === 'number' || inputMode === 'numeric') && getNumberProps())}
      />
    </div>
  );

  const PaymentInput = () => (
    <input
      className={`input--input--input ${disabled ? 'invalid' : ''}`}
      {...formProps}
      {...restProps}
    />
  );

  const SelectInput = () => (
    <div className={`input--select ${className}`}>
      <select
        className='input--select--select'
        name={name}
        data-testid={`input--select--select-${name}`}
        disabled={disabled}
        {...formProps}
      >
        <option value=''>Select...</option>
        {options?.map(({ value, label }) => <option key={value} value={value}>{label}</option>)}
      </select>
    </div>
  );

  const getInput = () => {
    if (isPaymentInput) {
      return PaymentInput();
    } else if (type === 'select') {
      return SelectInput();
    } else {
      return Input();
    }
  };

  return (
    <div className={`input ${className}`}>
      {Label()}
      {getInput()}
    </div>
  );
};
