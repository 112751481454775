import 'src/components/atoms/filter/index.scss';
import Icon from 'src/components/atoms/icon';
import React from 'react';
import { useTheme } from 'src/hooks';

interface Props {
  active?: boolean;
  label: string;
  onClick?: () => void;
}

export default ({ label, active, ...props }: Props) => {
  const { backgroundColor } = useTheme('secondary');
  const style = active ? {
    backgroundColor: `${backgroundColor}26`,
    borderColor: backgroundColor,
    fontWeight: 500
  } : {};

  return (
    <button
      className='filter'
      style={style}
      {...props}
    >
      {label}
      {active &&
        <Icon
          className='filter--icon'
          type='close'
          height='10'
          width='10'
        />
      }
    </button>
  );
};