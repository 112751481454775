import React from 'react';

interface Props {
  className?: string;
  fill?: string;
  height?: string;
  onClick?: () => void;
  width?: string;
}

export default ( { className, fill = 'black', height = '18', onClick, width = '18' }: Props ) => (
  <svg className={className} onClick={onClick} width={width} height={height} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M9 3.75V14.25' stroke={fill} strokeWidth='0.916667' strokeLinecap='round' strokeLinejoin='round' fill={fill}/>
    <path d='M3.75 9H14.25' stroke={fill} strokeWidth='0.916667' strokeLinecap='round' strokeLinejoin='round' fill={fill}/>
  </svg>
);