import React, { createContext, useContext, useReducer } from 'react';

const FormContext = createContext({});

const initial: any = { };

const reducer = (state = initial, { data, id, type }: any) => {
  if (!id && data ) { return { ...state }; }

  switch (type) {
    case 'CLEAR_FORM_DATA': return initial;
    case 'SAVE_FORM_DATA': return { ...state, [id]: data };
    case 'UPDATE_FORM_DATA': return { ...state, [id]: { ...state[id], ...data } };
    default: return { ...state, ...initial };
  }
};

export const FormProvider = ({ children }: any) => {
  const [ state, dispatch ] = useReducer(reducer, initial);

  const clearFormData = () => dispatch({ type: 'CLEAR_FORM_DATA' });
  const saveFormData = ({ data, id }: any) => dispatch({ data, id, type: 'SAVE_FORM_DATA' });
  const updateFormData = ({ data, id }: any) => dispatch({ data, id, type: 'UPDATE_FORM_DATA' });

  return (<FormContext.Provider value={{ ...state, clearFormData, saveFormData, updateFormData }}> {children} </FormContext.Provider>);
};

export const useFormData = () => {
  const context: any = useContext(FormContext);

  return {
    ...initial,
    clearFormData: () => undefined,
    saveFormData: () => undefined,
    updateFormData: () => undefined,
    ...context
  };
};
