import Add from 'src/icons/add';
import ArrowBack from 'src/icons/arrow-back';
import ArrowDown from 'src/icons/arrow-down';
import ArrowSelect from 'src/icons/arrow-select';
import ArrowUp from 'src/icons/arrow-up';
import Cart from 'src/icons/cart';
import Check from 'src/icons/check';
import Close from 'src/icons/close';
import Error from 'src/icons/error';
import Filter from 'src/icons/filter';
import Minus from 'src/icons/minus';
import NoResults from 'src/icons/no-results';
import Phone from 'src/icons/phone';
import Pin from 'src/icons/pin';
import React from 'react';
import Trash from 'src/icons/trash';

export const Icons = {
  add: Add,
  arrowBack: ArrowBack,
  arrowDown: ArrowDown,
  arrowSelect: ArrowSelect,
  arrowUp: ArrowUp,
  cart: Cart,
  check: Check,
  close: Close,
  error: Error,
  filter: Filter,
  minus: Minus,
  noResults: NoResults,
  phone: Phone,
  pin: Pin,
  trash: Trash
};

interface Props {
  className?: string;
  color?: string;
  height?: string;
  onClick?: () => void;
  type: keyof typeof Icons;
  width?: string;
}

export default ({ className, color, height, onClick, type, width }: Props) => {
  const Icon = Icons[type] ?? (() => <></>);

  return ( <Icon className={className} fill={color} height={height} onClick={onClick} width={width} /> );
};