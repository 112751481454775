import React, { ReactNode, createContext, useContext, useReducer } from 'react';

interface Action {
  type: string;
}

interface State {
  isVisible?: boolean;
  hideSpinner?: () => void;
  showSpinner?: () => void;
}

const SpinnerContext = createContext({});

const initial = { isVisible: false };

const reducer = (state = initial, { type }: Action) => {
  switch (type) {
    case 'SHOW_SPINNER': return { ...state, isVisible: true };
    case 'HIDE_SPINNER': return { ...state, isVisible: false };
    default: return { ...state, ...initial };
  }
};

export const SpinnerProvider = ({ children }: { children: ReactNode }) => {
  const [ state, dispatch ] = useReducer(reducer, initial);

  const showSpinner = () => dispatch({ type: 'SHOW_SPINNER' });
  const hideSpinner = () => dispatch({ type: 'HIDE_SPINNER' });

  return (
    <SpinnerContext.Provider value={{ ...state, hideSpinner, showSpinner }}> {children} </SpinnerContext.Provider>
  );
};

export const useSpinner = () => {
  const context: Partial<State> = useContext(SpinnerContext);

  return {
    ...initial,
    hideSpinner: () => undefined,
    showSpinner: () => undefined,
    ...context
  };
};

export default SpinnerContext;
