import React, { createContext, useContext, useReducer } from 'react';

import type { Children, EmptyFunction } from 'src/_types';

import type { InnerContent, ShowModal, UpdateModal } from 'src/context/modal/_types';

type Action = InnerContent & {
  type: string;
}

type State = InnerContent & {
  isVisible: boolean;
  hideModal?: EmptyFunction;
  showModal?: ShowModal;
  updateModal?: UpdateModal;
}

const ModalContext = createContext({});

const initial: State = { isVisible: false };

const reducer = (state = initial, { type, innerContent }: Action) => {
  switch (type) {
    case 'HIDE_MODAL': return { ...state, innerContent: null, isVisible: false };
    case 'SHOW_MODAL': return { ...state, innerContent, isVisible: true };
    case 'UPDATE_MODAL': {
      const isSameModal = state.innerContent?.key === innerContent?.key;
      const shouldUpdate = state.isVisible && isSameModal;

      return { ...state, ...(shouldUpdate && { innerContent }) };
    }
    default: return { ...state, ...initial };
  }
};

export const ModalProvider = ({ children }: Children) => {
  const [ state, dispatch ] = useReducer(reducer, initial);
  const hideModal = () => {

    dispatch({ type: 'HIDE_MODAL' }
    );
  };
  const showModal = ({ innerContent }: InnerContent = {}) => dispatch({ innerContent, type: 'SHOW_MODAL' });
  const updateModal = ({ innerContent }: InnerContent = {}) => dispatch({ innerContent, type: 'UPDATE_MODAL' });

  return (<ModalContext.Provider value={{ ...state, hideModal, showModal, updateModal }}> {children} </ModalContext.Provider>);
};

export const useModal = () => {
  const context: Partial<State> = useContext(ModalContext);

  return {
    ...initial,
    hideModal: () => undefined,
    showModal: () => undefined,
    updateModal: () => undefined,
    ...context
  };
};

export default ModalContext;
