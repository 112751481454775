import React from 'react';

interface Props {
  fill?: string;
}

export default ({ fill = 'black' }: Props) => (
  /* eslint-disable max-len */
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M4.9875 4.01172H9L10.9875 8.99922L8.475 10.4992C9.5625 12.6742 11.2875 14.4367 13.4625 15.4867L14.9625 12.9742L19.9875 14.9992V19.0117C19.9875 20.0992 19.0875 20.9992 18 20.9992C9.9375 20.5117 3.4875 14.0617 3 5.99922C3 4.91172 3.9 4.01172 4.9875 4.01172Z' stroke={fill} strokeWidth='1.3333' strokeMiterlimit='10.6667' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);