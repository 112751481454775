import 'src/components/atoms/link/index.scss';
import React from 'react';

interface Props {
  label: string;
  onClick: () => void;
  className?: string;
  hyperLinkClass?: string
}

export default ({ className = '', label, onClick, hyperLinkClass }: Props) => (
  <button className={`link ${className}`} onClick={onClick} style={{ marginBottom: '.5rem' }}>
    <span className={`link--label ${hyperLinkClass}`} role='link'>{label}</span>
  </button>
);
