import * as configcat from 'configcat-js';
import { useEffect, useState } from 'react';

const useConfigCat = () => {
  const [ commerceHubConfigs, setCommerceHubConfigs ] = useState({ form: {}, verification: {} });

  const client = configcat.getClient(process.env.CONFIG_CAT_KEY || '');

  useEffect(() => {
    const fetchConfigs = async () => {
      try {
        const configs = await client.getValueAsync(
          process.env.COMMERCE_HUB_CONFIG || '',
          ''
        );
        setCommerceHubConfigs(JSON.parse(configs));
        // eslint-disable-next-line no-console
        console.log('Commerce Hub Configs:', configs);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to fetch Commerce Hub Configs', error);
      }
    };

    fetchConfigs();
  }, [ client ]);

  return { commerceHubConfigs };
};

export default useConfigCat;
