/* eslint-disable max-len */
import React from 'react';

interface Props {
  fill?: string;
  height?: string;
  width?: string;
}

export default ({ fill = 'black', width = '7', height = '12' }: Props) => (
  <svg width={width} height={height} viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M6.29246 11.2894C6.68246 10.8994 6.68246 10.2694 6.29246 9.87937L2.41246 5.99937L6.29246 2.11937C6.68246 1.72937 6.68246 1.09937 6.29246 0.709367C5.90246 0.319366 5.27246 0.319366 4.88246 0.709367L0.292461 5.29937C-0.0975384 5.68937 -0.0975385 6.31937 0.292461 6.70937L4.88246 11.2994C5.26246 11.6794 5.90246 11.6794 6.29246 11.2894Z' fill={fill}/>
  </svg>
);
