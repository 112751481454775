import { useSiteMetadata } from 'src/hooks';

import type { GetQueryParams, ToUrl } from 'src/hooks/useUrl/_types';

export default () => {
  const { apiUrl } = useSiteMetadata();

  const getQueryParams: GetQueryParams = (search = window.location.search) => {
    const params = new URLSearchParams(search) ?? [];

    const formattedParams = Array.from(params.entries())
      .reduce((all: any, [ key, value ]: any = []) => ({
        ...all,
        [key]: all[key] ? [ value ].concat(all[key]) : value
      }), {});

    return formattedParams;
  };

  const toUrl: ToUrl = ({ base = apiUrl, href = '', query }) => {
    try {
      const { origin, pathname, search } = new URL(base + href);

      const queryParams = { ...getQueryParams(search), ...query };

      const path = `${origin}${pathname}?${new URLSearchParams(queryParams)}`
        .replace(/\?$/, '');

      return new URL(path, base) ?? {};
    } catch {
      return '';
    }
  };

  return { getQueryParams, toUrl };
};
