/* eslint-disable max-len */
import 'src/components/modals/address/index.scss';
import { AddressValidation, NewAddressValidation } from 'src/form-validations/address-modal';
import React, { useState } from 'react';
import { useApi, useApiState, useConfig, useModal } from 'src/hooks';
import Checkbox from 'src/components/atoms/checkbox';
import Form from 'src/components/atoms/form';
import Input from 'src/components/atoms/input';
import Link from 'src/components/atoms/link';
import Radio from 'src/components/atoms/radio';
import { STATE_OPTIONS } from 'src/constants';

interface Props {
  error: any;
  onComplete: any;
  onRetry: any;
}

const addressLabel = (address: any) => `${address.address1}${address.address2 ? '\n' + address.address2 : ''}\n${address.city}, ${address.state} ${address.zipcode}`;

export default ({ error, onComplete, onRetry }: Props) => {
  const { code, _meta, message } = error;
  const { retryCurrentAddress, retryNewAddress, retrySuggestedAddress } = useApi();
  const { zipcode } = useApiState() as any;
  const defaultValues = { zipcode };
  const { hideModal } = useModal();

  const retryRequest = (retryFn: any, data: any = {}) => {
    const onCompleteWithRetry = () => {
      onRetry?.(data);
      onComplete?.();
    };

    retryFn(data, onCompleteWithRetry, onRetry);
    hideModal();
  };

  const AddressVerfication = () => {
    const [ enterDifferentAddress, setEnterDifferentAddress ] = useState(false);
    const { overrideStyles } = useConfig();

    return ( !enterDifferentAddress
      ? <>
        <div className='address--sub-header'>{message}</div>
        <div className='address--label'>Is the below service address accurate?</div>
        <div className='address--formatted-address'>{addressLabel(_meta.address)}</div>
        <div className='address--form-container'>
          <Form
            buttonLabel='Confirm address'
            validation={AddressValidation}
            onSubmit={() => retryRequest(retryCurrentAddress)}
          >
            <Checkbox label='Yes, this is my correct address.' name='correctAddress' value='correctAddress'/>
          </Form>
          <Link className={`address--link ${overrideStyles?.hyperLinkClass}`} label='Enter a different address' onClick={() => setEnterDifferentAddress(true)} />
        </div>
      </>
      : <>
        <div className='address--sub-header'>Enter your service address below</div>
        <Form
          buttonLabel='Confirm address'
          defaultValues={defaultValues}
          validation={NewAddressValidation}
          onSubmit={(data) => retryRequest(retryNewAddress, data)}
        >
          <Input name='address1'label='Service address line 1' type='text'/>
          <Input name='address2' label='Apt. / unit #' type='text' secondaryLabel='Optional'/>
          <Input name='city' label='City' type='text'/>
          <div className='address--form-group'>
            <Input disabled name='zipcode' label='Zip code' type='text' inputMode='numeric'/>
            <Input name='state' label='State' type='select' options={STATE_OPTIONS}/>
          </div>
        </Form>
      </>
    );
  };

  const AddressComparison = () => (
    <>
      <div className='address--sub-header'>{message}</div>
      <Form
        buttonLabel='Confirm address'
        onSubmit={({ address }: { address: string }) => (
          address === 'suggested'
            ? retryRequest(retrySuggestedAddress, _meta.suggestedAddress)
            : retryRequest(retryCurrentAddress, _meta.address)
        )}
      >
        <div className='address--radio'>
          <label className='address--radio--label'>What you entered:</label>
          <Radio label={addressLabel(_meta.address)} name='address' value='current'/>
        </div>
        <div className='address--radio'>
          <label className='address--radio--label'>What we found:</label>
          <Radio label={addressLabel(_meta.suggestedAddress)} name='address' value='suggested'/>
        </div>
      </Form>
    </>
  );
  sessionStorage.setItem('ConfirmAddressModal', 'true');

  return (
    <div className='address'>
      <div className='address--header'>Confirm USPS address</div>
      {code === '400204' ? AddressVerfication() : AddressComparison()}
    </div>
  );};