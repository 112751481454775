import 'src/styles/global.scss';
import { ApiStateProvider } from 'src/context/apiState';
import { CartProvider } from 'src/context/cart/index';
import { FormProvider } from 'src/context/form';
import Layout from 'src/components/layout';
import { ModalProvider } from 'src/context/modal';
import React from 'react';
import { SpinnerProvider } from 'src/context/spinner';
import { ThemeProvider } from 'src/context/theme';

export const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
);

export const wrapRootElement = ({ element }) => (
  <FormProvider>
    <ApiStateProvider>
      <ThemeProvider>
        <ModalProvider>
          <SpinnerProvider>
            <CartProvider>
              {element}
            </CartProvider>
          </SpinnerProvider>
        </ModalProvider>
      </ThemeProvider>
    </ApiStateProvider>
  </FormProvider>
);
